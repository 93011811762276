<template>
  <div class="product">
    <van-nav-bar
        title="用户列表"
        left-text="返回"    
        left-arrow   
        @click-left="toHome"  
      />
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check='true'
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-search class="searchInp" v-model="searchValue" placeholder="请输入用户名关键词" shape='round' @clear='clearValue' @input="inputValue" fixed />            
      <van-cell-group class="mt10" v-for="(item, idx) in userList" :key="idx">
        <van-cell>
          <template #title>            
            <van-row type="flex" justify="space-between">                                     
              <van-col span="12"><h4 class="custom-title">{{item.username}}</h4></van-col>
              <van-col span="12"><h4 class="custom-title">{{item.mobile}}</h4></van-col>
            </van-row>                                              
            <van-row type="flex" justify="end"> 
              <van-col span="12">
                <van-field name="switch" label="初检人">
                  <template #input>
                    <van-switch :value="item.is_check_auth == 1 ? true : false" size="20" @click="changeCheck(item,'is_check_auth')" />
                  </template>
                </van-field>
              </van-col>
              <van-col span="12">
                <van-field name="switch" label="复检人">
                  <template #input>
                    <van-switch :value="item.is_recheck_auth == 1 ? true : false" size="20" @click="changeCheck(item,'is_recheck_auth')" />
                  </template>
                </van-field>
              </van-col>
              <van-col span="12" class="mt10">
                <van-field name="switch" label="审批人">
                  <template #input>
                    <van-switch :value="item.is_accept_auth == 1 ? true : false" size="20" @click="changeCheck(item,'is_accept_auth')" />
                  </template>
                </van-field>
              </van-col>
              <van-col span="12" class="mt10">
                <van-field name="switch" label="修改复检结果">
                  <template #input>
                    <van-switch :value="item.is_modify_result_auth == 1 ? true : false" size="20" @click="changeCheck(item,'is_modify_result_auth')" />
                  </template>
                </van-field>
              </van-col>
              <van-button class="mt10" plain hairline round type="info" size="mini" @click="depList(item.uid)">部门授权</van-button>
              <van-button class="mt10" plain hairline round type="info" size="mini" @click="toEdit(item.uid)">样品授权</van-button>                                                                   
            </van-row>            
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- 编辑 -->
    <van-popup v-model="toEditshow" class="elasticlayer tac" :closeable='true' position='bottom' round>
      <h3 class="tac">样品类型</h3>      
      <van-cell title="报检项目：" title-style="width:6.2em; flex:none">
        <van-checkbox-group v-model="groupChecked" direction="horizontal" @change='changeGroup'>
          <van-checkbox icon-size="24px" v-for="item in typeList" :key="item.id" :name="item.id" shape="square" style="margin-bottom: 5px;">{{item.type_name}}</van-checkbox>       
      </van-checkbox-group>
        </van-cell>
      <van-button class="" round block size="small" type="primary"  @click="preservEdit" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>

  <!-- 部门 -->
  <van-popup v-model="depShow" class="elasticlayer tac" :closeable='true' position='bottom' round>
      <h3 class="tac">部门列表</h3>      
      <van-cell title="报检部门：" title-style="width:6.2em; flex:none" class="dep-cell">
        <van-checkbox-group v-model="depChecked" direction="horizontal" @change='changeDep'>
          <van-checkbox icon-size="24px" v-for="item in depsList" :key="item.dd_dep_id" :name="item.dd_dep_id" shape="square" style="margin-bottom: 5px;">{{item.dd_dep_name}}</van-checkbox>       
      </van-checkbox-group>
        </van-cell>
      <van-button class="" round block size="small" type="primary"  @click="predepEdit" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue';
import { List, Cell,CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Dialog, Icon, Search, Switch, Form, CheckboxGroup, Checkbox,NavBar } from 'vant';
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Search);
Vue.use(Switch);
Vue.use(Form);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(NavBar);
export default {  
  name: 'UserList',
  components: {},
  
  data() {    
    return {
      count: 0,
      page: 1,
      offset: 10,      
      searchValue: '',     
      loading: false,
      finished: false,      
      addShow: false,
      toEditshow: false, 
      depShow: false, 
      editId: '', //编辑id     
      userList: [], //用户列表
      typeList: [], //检测项目
      uid: '', //用户id
      typeIds: [], // 选择检测样品种类
      groupChecked: [],
      depChecked: [],
      depsList: [],
      depIds: []
    };
  },
  created: function () {          
  },
  mounted() {    
  },
  methods: {   
    onLoad () {
      var params = {
        page: this.page++,
        offset: this.offset,
        username: this.searchValue
      }
      this.$get('user/get-user-list', 'api', params).then(res => {        
        var data = res.data.data             
        var length1 = res.data.data.length
        var length2 = res.data.count
        this.count = res.data.count
        this.loading = false;
        this.userList = this.userList.concat(data);//追加数据           
        if (length1 >= length2) {  //数据全部加载完成
          this.finished = true;         
        }
      }).catch(err => {
        console.log(err)
        this.finished = true;
      })      
    },
    // 搜索
    inputValue(value) { 
      this.searchValue = value    
      this.userList = []
      this.finished = false;
      this.loading = true 
      this.page = 1
      this.onLoad()                              
    },        
    // 点击 x
    clearValue() {
      this.userList = []
      this.finished = false;
      this.loading = true 
      this.page = 1
      this.onLoad()      
    },
    // 点击开关 是否是审批人
    changeCheck(item,value) {      
      var params = {}
      if(value == 'is_check_auth') {
        if(item.is_check_auth == '0') {
          item.is_check_auth = 1
        }else {
          item.is_check_auth = 0
        }
        params = {
          is_check_auth: item.is_check_auth,
          uid: item.uid
        }
      } else if(value == 'is_recheck_auth') {
        if(item.is_recheck_auth == '0') {
          item.is_recheck_auth = 1
        }else {
          item.is_recheck_auth = 0
        }
        params = {
          is_recheck_auth: item.is_recheck_auth,
          uid: item.uid
        }        
      }else if(value == 'is_accept_auth') {
        if(item.is_accept_auth == '0') {
          item.is_accept_auth = 1
        }else {
          item.is_accept_auth = 0
        }
        params = {
          is_accept_auth: item.is_accept_auth,
          uid: item.uid
        }
      }else if(value == 'is_modify_result_auth') {
        if(item.is_modify_result_auth == '0') {
          item.is_modify_result_auth = 1
        }else {
          item.is_modify_result_auth = 0
        }
        params = {
          is_modify_result_auth: item.is_modify_result_auth,
          uid: item.uid
        }
      }     
      this.$post('user/set-user-auth', 'api', params).then(res => {
        this.$notify({ type: 'success', message: res.message })   
        this.finished = false;
        this.loading = true    
        this.page = 1
        this.userList = []
        this.onLoad()
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })     
      })
    },     
    // 权限 检测项目列表
    toEdit(id) {       
      this.toEditshow = true   
      this.uid = id 
      this.$post('user/get-user-own-check-auth', 'api', {uid: id }).then(res => {
        var data = res.data      
        this.groupChecked= data         
        return        
      }, err => {
        console.log(err)
        this.groupChecked = []
      }) 
      var params = {
        page: 1,
        offset: 10,
        type_name: ''
      }      
      this.$get('sample-type/get-sample-type-list', 'api', params).then(res => {       
        var data = res.data.list        
        this.typeList = data         
        return        
      }, err => {
        console.log(err)
        Toast.fail(err.message);
      })            
    },
    // 点击复选框
    changeGroup(item) { 
      this.typeIds = item
    },
    // 复选保存
    preservEdit() {   
      var that = this
      this.toEditshow = false      
      var params = {
        uid: this.uid,
        type_ids: this.typeIds      
      }            
      this.$post('user/grant-sample-type-auth', 'api', params).then(res => {
        this.$notify({ type: 'success', message: res.message })
        this.finished = false;
        this.loading = true    
        that.page = 1
        that.userList = []
        that.onLoad()
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })        
      })
    },
    // 部门列表
    depList(id) {    
      this.depShow = true              
      this.uid = id 
      this.$post('user/get-user-own-dept-auth', 'api', { uid: id }).then(res => {
        var data = res.data                    
        this.depChecked= data         
        return        
      }, err => {
        console.log(err)
        this.depChecked = []
      })

      var params = {
        page: 1,
        offset: 10,
        type_name: ''
      }      
      this.$get('inspect/get-inspect-dep-list', 'api', params).then(res => {              
        var data = res.data.list
        this.depsList = data         
        return        
      }, err => {
        console.log(err)
        Toast.fail(err.message);
      })            
    },
    // 点击部门复选
    changeDep(item) {      
      this.depIds = item
    },
    // 部门复选
    predepEdit() {   
      var that = this
      this.depShow = false      
      var params = {
        uid: this.uid,
        dept_ids: this.depIds      
      }            
      this.$post('user/grant-user-dept-auth', 'api', params).then(res => {
        this.$notify({ type: 'success', message: res.message })
        this.finished = false;
        this.loading = true    
        that.page = 1
        that.userList = []
        that.onLoad()
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })        
      })
    },
    // 返回
    toHome() {
      this.$router.push({
        path: '/home'     
      })
    }               
  }          
};
</script>
<style scoped>
 .elasticlayer {
   height: 60%;
 }
 .tac {
   text-align: center;
 } 
 .red {
   color: red;
 }
.searchInp {
  /* position: fixed;
  top: 0; */
  width: -webkit-fill-available;
  z-index: 99;
}
.van-row--justify-end .van-cell {
  padding: 0;
}
.dep-cell {
  height: 100%;
}
.dep-cell >>> .van-cell__value{
  overflow: scroll;
  height: 62%;
}
</style>